.form-section{
    margin: 10px 0;
    padding: 10px;
}

.form-header h3{
    margin: 20px;
}

.form-border{
    border:1px solid rgb(211, 207, 207);
    margin: 10px;
    border-radius: 10px;
}