.link{
    color:white;
    text-decoration: none;
}


.bar-links{
    text-decoration: none;
    color: #ffffff;
    font-size: 1.5em;
    background-color: #0d98d3;
}

.bar-links:hover{
    background-color: #ffffff;
    color: #004462;
    height:fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
}

.Strong p{
    color:green
}

.Weak p{
    color:pink;
}

body, h1, h2, h3, p, ul, li, form, input, textarea, button {
    margin: 0;
    padding: 0 0;
    font-family: 'Poppins', sans-serif;
    color: #080324;
}

body {
    font-family: Arial, sans-serif;
    /* background-color: #f0f0f0; */
}

#header {
    background-color:#f0f0ff;
    color: #007bff;
    text-align: center;
    padding: 5px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    font-family: 'Poppins', sans-serif;
   
}

.header .logo img {
    max-width: 100px;
}

.search-box {
    display: none;
}

.intro{
    background-color: #f0f0ff;
    padding:20px;
    color: #007bff;
    display: flex;
}


/* 
.intro .blocks .feature{
    border-radius: 50px;
    background-color: #007bff;
    color: #fff;
    border: solid 3px;
    font-size: 1.8vw;
} */

/* .intro img{
    width: 70%;
    height: auto;
} */

/* .intro p{
    padding:1%;
} */

#intro h1 {
    font-size: 400%;
    color: #007bff;
    font-family: 'Poppins', sans-serif;
    margin-top: 20px;
}

.header-obj a{
    text-decoration: none;
    margin: 5px;
    color:#080324;
    font-size: 20px;
    text-align:end;
    font-family: 'Noto Sans', sans-serif;

}

.header-obj a:visited{
    color: grey;
}

.header-obj a:hover {
    color: #b11510;
}

.header .cta p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.btn {
    display: inline-block;
    color: #007bff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    font-weight: bold;
    border-radius: 10px;
    transition: background-color 0.3s;
    margin: 0 1px;
}

.btn:hover {
    background-color: #ffffff;
    color: #fff;
}

.btn:click{
    text-decoration: underline;
}

#left-header-buttons{
    margin-left: auto;
    display: flex;
    margin-right: 20px;
}

.left-btn{
    padding: 0px;
}

#special-btn{
    color: #ffffff;
    background-color: #4c88c9;
    width: fit-content-;

}


body {
    padding-top: 40px;
}

.blocks2{
    display: flex;
    flex-wrap: wrap;
}
.blocks{
    display: flex;
}

.header .logo img {
    
    width: 50;
    height: 50;
}

.header .cta h1 {
    font-size: 2rem;
    margin: 20px 0;
}

.header .cta p {
    font-size: 1rem;
    margin-bottom: 20px;
}


.btn:hover {
    background-color: #ffffff;
    color: #0056b3;
}

#features {
    padding: 40px 0;
    text-align: center;
}

#features h2{
    text-align: left;
    padding-left: 10px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    background-color: #080324;
    background-color:#4c88c9;
    color: #fff;
    padding: 10px;
}

.feature h2{
    margin:10px;
}

.feature {
    margin: 10px;
    border:2px grey solid;
    background-color: white;
    box-shadow: 5px 5px 10px #888888;
    padding: 7px;
}

.feature img {
    width: 100%;
    border-radius: 25px 0 0 0 10px;
    height: auto;
}
.feature h3{
    font-family:cursive;    
}

.feature p{
    font-size: 80%;
    font-family:cursive;
}

.how-we-help .blocks{
    display: flex;
    margin: 10px;
    border:1px solid black;
    background-color: #080324;
}

.how-we-help .blocks p{  
    font-size: 1.3em;
}

#pricing {
    background-color: #f0f0f0;
    text-align: center;
    padding: 40px 0;
}

.plan {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 25px;
    width: 10cm;
    background-color: #080324;
    color: #fff;
}

.plan h3 {
    font-size: 1.5rem;
    margin: 10px 0;
    color: #fff;
}

.plan p {
    font-size: 1rem;
    margin: 10px 0;
    color: #fff;
    
}

.price{
    font-size: 30px;
    text-decoration:solid;
}

.plan ul {
    list-style-type: disc; /* Use 'disc' for bullets */
    padding-left: 15%;
    padding: 10px;
    color: #fff;
}

.plan ul li {
    text-align: left; /* Reset text alignment for list items */
    margin: 0; /* Reset margin */
    margin-right: 10px;
    padding: 7px;
    color: #fff;
}

#contact {
    text-align: center;
    padding: 40px 0;
}

form {
    margin: 20px;
    text-align: left;
}

form input, form textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

form button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
}

form button:hover {
    background-color: #0056b3;
}

#contact h2{
    text-align: left;
    /* margin: 10px; */
    color: #b11510;
}

#contact p{
    text-align: left;
    /* margin: 10px; */
}

#details{
    padding: 20px;
    text-align: left;
    background-color: #080324;
    background-color:#050a30;
    color: #fff;

}

.download-app{
    border: 2px solid white;
    border-radius: 20px;
    height: fit-content;
    width: fit-content;
    padding: 10px;
}


.download-app-link a{
    text-decoration: none;
    color: white;
}

.download-app-link a:hover{
    text-decoration: none;
    color: white;
}
#details h3{
    color: #080324;
}

#details a{
    color: white;
    padding-left: 0px;
}

#details a:hover{
    color: blue;
}

.detail-col{
    margin: 20px;
    text-align: left;
}

.detail-data{
    margin: 30px 0 0 0;
    text-align: left;
    font-family: 'Poppins', sans-serif;
}

.detail-data a{
    text-decoration: none;
    color: #007bff;
}

.detail-data a:hover{
    text-decoration: none;
    color: #fff;
}

footer {
    background-color: grey;
    color: #fff;
    text-align: center;
    padding-bottom: 0;
}


.social {
    list-style: none;
    padding: 0;
}

.social li {
    display: inline;
    margin: 0 10px;
}

footer a{
    text-decoration: none;
    color: #000;
    font-size: 12px;
    margin:0 10px ;

}

footer a:hover{
    text-decoration: underline;
}

.final-links{
    display: flex;
    margin: 10px;
    color:#050a30;
    
}

.floor{
    background-color: #f8f8f818;
    padding: 0px;
    margin: 0px;
}

.final-links p{
    font-size: 12px;
    margin-left: auto;
}

.final-links a{
    text-decoration: none;
    padding: 10px 10px 0 10px;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
}

.contact-us-logos{
    color:#050a30;
    margin-right: 20px;
}

.whyUsCard{
    margin: 10%;
}

#whyUsInnerCard{
    background-color:#d7b236;
    width: 16rem;
    height:23rem;
    border:solid 0px;
    margin:2vw;
}

.services-container{
    margin:20px;
}

.services-container h2{
    font-weight: bold;
    font-size: 300%;
}

.footer-link{
    color:#f9f9f9
}

